import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
    ERentOrOwn,
    ESteps,
    IStepsState,
    TCustomerAddressInformation,
    TCustomerContactInformation,
    TCustomerDateOfBirth,
    TCustomerFirstName,
    TCustomerHousingDetails,
    TCustomerIncome,
    TCustomerIncomeAndSinInformation,
    TCustomerLastName,
    TCustomerNameAndDobInformation,
    TCustomerSin,
    TEmails,
    THousingPayment,
    THousingSince,
    TLoanBorrowInformation,
    TLoanReasonInformation,
    TPhoneNumbers,
} from './types';

export const initialState: IStepsState = {
    currentStep: 0,
    customerInformation: {
        address: {
            city: '',
            postalCode: '',
            province: '',
            streetName: '',
            unit: undefined,
        },
        dob: { day: '', month: '', year: '' },
        emailAddress: '',
        firstName: '',
        housingDetails: {
            monthlyAmount: undefined,
            monthsLived: 0,
            rentOrOwn: ERentOrOwn.nothing,
            yearsLived: 0,
        },
        income: undefined,
        lastName: '',

        mobilePhone: '',

        otherPhone: '',
        sin: '',

        stayUpToDate: false,
    },
    loanInformation: {
        borrow: 5000,
    },
    stepVisited: ESteps.INTRODUCTION,
    totalSteps: 7,
};

export const stepsSlice = createSlice({
    initialState,
    name: 'steps',
    reducers: {
        resetSteps(state) {
            state.currentStep = 0;
            state.customerInformation = initialState.customerInformation;
            state.loanInformation = initialState.loanInformation;
            state.stepVisited = ESteps.INTRODUCTION;
        },
        updateAddressInfo(state, { payload }: PayloadAction<TCustomerAddressInformation>) {
            state.customerInformation.address = payload;
        },
        updateBorrowInfo(state, { payload }: PayloadAction<TLoanBorrowInformation['borrow']>) {
            state.loanInformation.borrow = payload;
        },
        updateContactInfo(state, { payload }: PayloadAction<TCustomerContactInformation | TPhoneNumbers | TEmails>) {
            state.customerInformation = { ...state.customerInformation, ...payload };
        },
        updateHousingDetailsInfo(
            state,
            { payload }: PayloadAction<TCustomerHousingDetails | THousingSince | THousingPayment>
        ) {
            // clear the monthly amount if customer does not rent or own, since we don't need it
            if ('rentOrOwn' in payload && 'monthlyAmount' in payload && payload.rentOrOwn === ERentOrOwn.nothing) {
                payload.monthlyAmount = undefined;
            }
            state.customerInformation.housingDetails = { ...state.customerInformation.housingDetails, ...payload };
        },
        updateIncomeInfo(
            state,
            { payload }: PayloadAction<TCustomerIncomeAndSinInformation | TCustomerIncome | TCustomerSin>
        ) {
            state.customerInformation = { ...state.customerInformation, ...payload };
        },
        updateNameAndDobInfo(
            state,
            {
                payload,
            }: PayloadAction<
                TCustomerNameAndDobInformation | TCustomerDateOfBirth | TCustomerFirstName | TCustomerLastName
            >
        ) {
            state.customerInformation = { ...state.customerInformation, ...payload };
        },
        updateReasonInfo(state, { payload }: PayloadAction<TLoanReasonInformation['reason']>) {
            state.loanInformation.reason = payload;
        },
        updateStepNumber(state, action: PayloadAction<IStepsState['currentStep']>) {
            state.currentStep = action.payload;
        },
        updateStepVisited(state, action: PayloadAction<IStepsState['stepVisited']>) {
            state.stepVisited = action.payload;
        },
    },
});
