import { IQuotePayload } from 'store/redux/modules/application/types';
import { RootState } from 'types/store';
import { calculateTime, CITY_MAX_LENGTH, STREETNAME_MAX_LENGTH, UNIT_MAX_LENGTH } from 'utils/constants';

export const buildQuote = (language: string, brazeId: string, store: RootState): IQuotePayload => {
    const { customerInformation, loanInformation } = store.steps;
    const { application } = store;
    const { day, month, year } = customerInformation.dob;

    const quote = {
        brazeId: brazeId || '',
        consentInfo: {
            creditInquiry: true,
            marketingEmail: customerInformation.stayUpToDate,
        },
        duration: calculateTime(application.loanStartTime),

        referralInfo: {
            amount: loanInformation.borrow,
            annualIncome: customerInformation.income,
            city: customerInformation.address.city
                .normalize('NFKD')
                .replace(/[\u0300-\u036F]/g, '')
                .slice(0, CITY_MAX_LENGTH),
            dateOfBirth: `${year}-${month}-${day}`,
            email: customerInformation.emailAddress,
            firstName: customerInformation.firstName.normalize('NFKD').replace(/[\u0300-\u036F]/g, ''),
            housingStatus:
                (customerInformation.housingDetails.rentOrOwn === 'rent' && '1') ||
                (customerInformation.housingDetails.rentOrOwn === 'own' && '2') ||
                '5',
            lastName: customerInformation.lastName.normalize('NFKD').replace(/[\u0300-\u036F]/g, ''),
            loanPurpose: loanInformation.reason,
            mobilePhone: customerInformation.mobilePhone,
            monthlyHousingCost: customerInformation.housingDetails.monthlyAmount || 0,
            otherPhone: customerInformation.otherPhone,
            postalCode: customerInformation.address.postalCode,
            preferredLanguage: language,
            province: customerInformation.address.province,
            socialInsuranceNumber: customerInformation.sin || null,
            streetAddress: customerInformation.address.streetName
                .normalize('NFKD')
                .replace(/[\u0300-\u036F]/g, '')
                .slice(0, STREETNAME_MAX_LENGTH),
            timeAtAddress: {
                months: String(customerInformation.housingDetails.monthsLived),
                years: String(customerInformation.housingDetails.yearsLived),
            },
            unit: customerInformation.address.unit
                ? String(customerInformation.address.unit).slice(0, UNIT_MAX_LENGTH)
                : '',
        },
        source: (window as any).fairstoneLayer ? JSON.stringify((window as any).fairstoneLayer) : '',
    };
    return quote;
};
