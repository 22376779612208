import { TrackJS } from '@fairstone-frontend/utils/core/logs';
import { Middleware } from 'redux';

import { RootState } from 'types/store';
import { redirect } from 'utils/redirect';

import { ON_RESET_EVENT } from './actions';
import { persistedStore } from './store';

export const onResetEventMiddleware: Middleware<
    // eslint-disable-next-line @typescript-eslint/ban-types
    {}, // Most middleware do not modify the dispatch return value
    RootState
> = () => (next) => async (action) => {
    if (action.type === ON_RESET_EVENT) {
        try {
            await persistedStore.purge();
        } catch (error: any) {
            TrackJS.track(error);
        }

        let redirectPath = action.payload.path;

        if (!action.payload.path) {
            redirectPath = action.payload.locale.includes('fr') ? '/fr' : '/';
        }

        redirect(redirectPath);

        return;
    }
    return next(action);
};
