import React, { useEffect, useState } from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import { useBrazeNotifications } from '@fairstone/functional/core/components/BrazeNotifications/BrazeNotificationsProvider';
import { useSessionManager } from '@fairstone/functional/core/components/SessionManager/SessionManagerProvider';
import { t } from '@fairstone/ui/core/utils/translate';

import AppLayout from 'layouts/App';
import ErrorPage from 'pages/Error';

const AppError = () => {
    throw new Error('Internal Server Error');
};

const ErrorFallback = (props: FallbackProps) => (
    <AppLayout disableHeader>
        <ErrorPage buttonText={t('error.try-again')} title={t('error.500')} {...props} />
    </AppLayout>
);

const App: React.FC<React.PropsWithChildren> = ({ children }) => {
    const [error, setError] = useState(false);
    const { start: sessionManagerTimer } = useSessionManager();
    const { startInactivityTimer: brazeNotificationsTimer } = useBrazeNotifications();

    useEffect(() => {
        const { pathname } = window.location;
        if (pathname !== '/') {
            // Resetting the timers when users refresh the page
            sessionManagerTimer();
            brazeNotificationsTimer();
        }
    }, [sessionManagerTimer, brazeNotificationsTimer]);

    return (
        <>
            <ErrorBoundary
                FallbackComponent={ErrorFallback}
                onReset={() => {
                    setError(false);
                }}
                resetKeys={[error]}
            >
                {error ? <AppError /> : children}
            </ErrorBoundary>
        </>
    );
};
export default App;
