import React from 'react';
import { icons } from '@fairstone/ui/core';
import { Card } from '@fairstone/ui/core/components/Card';
import { Link } from '@fairstone/ui/core/components/Link';
import { Typography } from '@fairstone/ui/core/components/Typography';
import { t, translate } from '@fairstone/ui/core/providers/Intl/locales/translate';

import styles from './CommonTips.module.scss';

interface ICommonTipsProps {
    scenario?: 'DECLINED' | 'ERROR';
}

const CommonTips: React.FC<ICommonTipsProps> = (props) => (
    <Card className={styles.commonTips}>
        {props.scenario === 'DECLINED' && (
            <>
                <Typography variant="h3">{t('pages.instantQuote.instantQuoteRejectedTextP1')}</Typography>
                <Typography component="p" variant="body3">
                    {t('pages.instantQuote.instantQuoteRejectedTextP2')}
                </Typography>{' '}
                <Link className={styles.link} href={translate('pages.instantQuote.commons.homeUrl')} target="_blank">
                    {t('pages.instantQuote.commons.checkItOut')}
                </Link>
            </>
        )}

        {props.scenario === 'ERROR' && (
            <>
                <Typography variant="h3">{t('pages.instantQuote.commons.inTheMeantime')}</Typography>
                <Typography component="p" variant="body3">
                    {t('error.errorPageHint')}
                </Typography>

                <Link className={styles.link} href={translate('global.link.fairstoneBlog')} target="_blank">
                    {t('pages.instantQuote.commons.checkItOut')}
                </Link>
            </>
        )}

        {!props.scenario && (
            <>
                <Typography variant="h3">{t('pages.instantQuote.commons.inTheMeantime')}</Typography>
                <Typography component="p" variant="body3">
                    {t('pages.instantQuote.commons.referUs', {
                        a: (chunks: string) => (
                            <Link href={translate('pages.instantQuote.commons.referUsUrl')} target="_blank">
                                {chunks}
                            </Link>
                        ),
                    })}
                </Typography>
                <Link className={styles.link} href={translate('pages.instantQuote.commons.homeUrl')} target="_blank">
                    {t('pages.instantQuote.commons.checkItOut')}
                    <icons.NavigateNext className={styles.nextIcon} />
                </Link>
            </>
        )}
    </Card>
);

export default CommonTips;
