import { RootState } from 'types/store';

import { applicationSlice } from './reducers';
import { IApplicationState, IQuote } from './types';

export const selectIqAuth = (state: RootState): IApplicationState['IQ_AUTH'] => state.application.IQ_AUTH;
export const selectLoading = (state: RootState): IApplicationState['loading'] => state.application.loading;
export const selectQuote = (state: RootState): IQuote => state.application.instantQuote;

export const selectError = (state: RootState): IApplicationState['error'] => state.application.error;

export const selectLoanStartTime = (state: RootState): IApplicationState['loanStartTime'] =>
    state.application.loanStartTime;

export const selectFeatureFlags = (state: RootState): IApplicationState['featureFlags'] =>
    state.application.featureFlags;

export const selectbrazeId = (state: RootState): IApplicationState['brazeId'] => state.application.brazeId;

export const { resetApplication, setbrazeId, updateFeatureFlags, updateIqAuth, updateLoanStartTime } =
    applicationSlice.actions;

export default applicationSlice.reducer;
