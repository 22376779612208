import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types/store';

import { stepsSlice } from './reducers';
import {
    IStepsState,
    TCustomerAddressInformation,
    TCustomerContactInformation,
    TCustomerDateOfBirth,
    TCustomerFirstName,
    TCustomerHousingDetails,
    TCustomerIncome,
    TCustomerIncomeAndSinInformation,
    TCustomerLastName,
    TCustomerNameAndDobInformation,
    TCustomerSin,
    TEmails,
    THousingPayment,
    THousingSince,
    TLoanBorrowInformation,
    TLoanReasonInformation,
    TPhoneNumbers,
} from './types';

export const selectStepState = (state: RootState): IStepsState => state.steps;

export const selectBorrowInfo = createSelector(
    [(state: RootState): TLoanBorrowInformation => state.steps.loanInformation],
    (borrow) => borrow
);

export const selectReasonInfo = createSelector(
    [(state: RootState) => state.steps.loanInformation.reason],
    (reason): TLoanReasonInformation => ({ reason })
);

export const selectNameAndDobInfo = createSelector(
    [
        (state: RootState) => state.steps.customerInformation.dob,
        (state: RootState) => state.steps.customerInformation.firstName,
        (state: RootState) => state.steps.customerInformation.lastName,
    ],
    (dob, firstName, lastName): TCustomerNameAndDobInformation => ({
        dob,
        firstName,
        lastName,
    })
);

export const selectFirstName = createSelector(
    [(state: RootState) => state.steps.customerInformation.firstName],
    (firstName): TCustomerFirstName => ({ firstName })
);

export const selectLastName = createSelector(
    [(state: RootState) => state.steps.customerInformation.lastName],
    (lastName): TCustomerLastName => ({ lastName })
);

export const selectDob = createSelector(
    [(state: RootState) => state.steps.customerInformation.dob],
    (dob): TCustomerDateOfBirth => ({ dob })
);

export const selectAddress = createSelector(
    [(state: RootState) => state.steps.customerInformation.address],
    (address): TCustomerAddressInformation => address
);

export const selectHousingDetailsInfo = createSelector(
    [
        (state: RootState) => state.steps.customerInformation.housingDetails.monthlyAmount,
        (state: RootState) => state.steps.customerInformation.housingDetails.monthsLived,
        (state: RootState) => state.steps.customerInformation.housingDetails.rentOrOwn,
        (state: RootState) => state.steps.customerInformation.housingDetails.yearsLived,
    ],
    (monthlyAmount, monthsLived, rentOrOwn, yearsLived): TCustomerHousingDetails => ({
        monthlyAmount,
        monthsLived,
        rentOrOwn,
        yearsLived,
    })
);

export const selectHousingSince = createSelector(
    [
        (state: RootState) => state.steps.customerInformation.housingDetails.monthsLived,
        (state: RootState) => state.steps.customerInformation.housingDetails.yearsLived,
    ],
    (monthsLived, yearsLived): THousingSince => ({ monthsLived, yearsLived })
);

export const selectHousingPayment = createSelector(
    [
        (state: RootState) => state.steps.customerInformation.housingDetails.monthlyAmount,
        (state: RootState) => state.steps.customerInformation.housingDetails.rentOrOwn,
    ],
    (monthlyAmount, rentOrOwn): THousingPayment => ({ monthlyAmount, rentOrOwn })
);

export const selectContactInfo = createSelector(
    [
        (state: RootState) => state.steps.customerInformation.emailAddress,
        (state: RootState) => state.steps.customerInformation.mobilePhone,
        (state: RootState) => state.steps.customerInformation.otherPhone,
        (state: RootState) => state.steps.customerInformation.stayUpToDate,
    ],
    (emailAddress, mobilePhone, otherPhone, stayUpToDate): TCustomerContactInformation => ({
        emailAddress,
        mobilePhone,
        otherPhone,
        stayUpToDate,
    })
);

export const selectPhoneNumbers = createSelector(
    [
        (state: RootState) => state.steps.customerInformation.mobilePhone,
        (state: RootState) => state.steps.customerInformation.otherPhone,
    ],
    (mobilePhone, otherPhone): TPhoneNumbers => ({
        mobilePhone,
        otherPhone,
    })
);

export const selectEmails = createSelector(
    [
        (state: RootState) => state.steps.customerInformation.emailAddress,
        (state: RootState) => state.steps.customerInformation.stayUpToDate,
    ],
    (emailAddress, stayUpToDate): TEmails => ({
        emailAddress,
        stayUpToDate,
    })
);

export const selectIncomeInfo = createSelector(
    [
        (state: RootState) => state.steps.customerInformation.income,
        (state: RootState) => state.steps.customerInformation.sin,
    ],
    (income, sin): TCustomerIncomeAndSinInformation => ({ income, sin })
);

export const selectIncome = createSelector(
    [(state: RootState) => state.steps.customerInformation.income],
    (income): TCustomerIncome => ({ income })
);

export const selectSin = createSelector(
    [(state: RootState) => state.steps.customerInformation.sin],
    (sin): TCustomerSin => ({ sin })
);

export const selectCurrentStep = (state: RootState): IStepsState['currentStep'] => state.steps.currentStep;
export const selectStepVisited = (state: RootState): IStepsState['stepVisited'] => state.steps.stepVisited;
export const selectTotalSteps = (state: RootState): IStepsState['totalSteps'] => state.steps.totalSteps;

export const {
    resetSteps,
    updateAddressInfo,
    updateBorrowInfo,
    updateContactInfo,
    updateHousingDetailsInfo,
    updateIncomeInfo,
    updateNameAndDobInfo,
    updateReasonInfo,
    updateStepNumber,
    updateStepVisited,
} = stepsSlice.actions;

export default stepsSlice.reducer;
