import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';

import allianceUserReducer from './modules/allianceUser';
import applicationReducer from './modules/application';
import stepsReducer from './modules/steps';
import yourQuoteReducer from './modules/yourQuote';
import { applicationPersistConfig } from './store-persist-config';

export default combineReducers({
    allianceUser: allianceUserReducer,
    application: persistReducer(applicationPersistConfig, applicationReducer),
    steps: stepsReducer,
    yourQuote: yourQuoteReducer,
});
