import React from 'react';
import { ISessionManagerProvider } from '@fairstone/functional/core/components/SessionManager/SessionManagerProvider';
import { t } from '@fairstone/ui/core/utils/translate';

import { SessionPopUpScreen } from 'components/SessionPopUpScreen/SessionPopUpScreen';

export const sessionModalText: ISessionManagerProvider['text'] = {
    action: t('session.popup.actionButton'),
    title: <SessionPopUpScreen />,
};
