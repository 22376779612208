import { useCallback, useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { isEmpty } from 'lodash';

import { applicationType } from './constants';

export const GA_STEP_NAME_EVENT = 'step_change';
export const GA_INSTANT_QUOTE_RESPONSE_EVENT = 'instant_quote_response';
export const GA_STEP_NAMES = new Map<string, string>([
    ['/loan-application/amount', 'Loan Application - Borrow'],
    ['/loan-application/reason', 'Loan Application - Reason'],
    ['/loan-application/demographic', 'Loan Application - Name and DoB'],
    ['/loan-application/address', 'Loan Application - Address'],
    ['/loan-application/housing-details', 'Loan Application - Housing'],
    ['/loan-application/contact-info', 'Loan Application - Phone and Email'],
    ['/loan-application/income', 'Loan Application - Income'],
    ['/loan-application/review', 'Loan Application - Review'],
    ['/loan-application/your-quote', 'Demande De Prêt - Your quote'],
    ['/fr/demande-de-pret/montant', 'Demande De Prêt - Emprunter'],
    ['/fr/demande-de-pret/raison', 'Demande De Prêt - Raison'],
    ['/fr/demande-de-pret/demographique', 'Demande De Prêt - Nom et date de naissance'],
    ['/fr/demande-de-pret/adresse', 'Demande De Prêt - Adresse'],
    ['/fr/demande-de-pret/details-du-logement', 'Demande De Prêt - Logement'],
    ['/fr/demande-de-pret/informations-de-contact', 'Demande De Prêt - Téléphone et courriel'],
    ['/fr/demande-de-pret/revenus', 'Demande De Prêt - Revenu'],
    ['/fr/demande-de-pret/revision', 'Demande De Prêt - Révision'],
    ['/fr/demande-de-pret/votre-soumission', 'Demande De Prêt - Votre devis'],
]);

export interface IDataLayer {
    [key: string]: string;
}

declare global {
    interface Window {
        dataLayer: any[];
    }
}

interface IAnalyticsServicesHook {
    pushDataLayer: (data?: IDataLayer | undefined) => void;
    getVariableFromDataLayer: (variableName: string) => any;
    pushSubmitEvent: () => void;
}

export const useAnalyticsServices = (): IAnalyticsServicesHook => {
    useEffect(() => {
        push();
    }, []);

    const pushDataLayer = useCallback(function (data?: IDataLayer) {
        push(data);
    }, []);

    const getVariableFromDataLayer = (variableName: string): any => {
        if (typeof window !== 'undefined' && window.dataLayer) {
            const variableEntry = window.dataLayer.find((entry) => entry.hasOwnProperty(variableName));
            if (variableEntry) {
                return variableEntry[variableName];
            }
        }
        return undefined;
    };

    const pushSubmitEvent = () => {
        TagManager.dataLayer({
            dataLayer: {
                event: 'submit',
            },
        });
    };

    return { getVariableFromDataLayer, pushDataLayer, pushSubmitEvent };
};

export const push = (customData?: IDataLayer): void => {
    const { pathname, search } = window.location;
    let data = {};

    if (GA_STEP_NAMES.get(pathname)) {
        data = {
            application_type: applicationType,
            event: GA_STEP_NAME_EVENT,
            vp_current_page_name: GA_STEP_NAMES.get(pathname),
            vp_current_page_url: pathname + (search || ''),
        };
    }

    if (customData) {
        data = { ...data, ...customData };
    }

    if (!isEmpty(data)) {
        TagManager.dataLayer({
            dataLayer: data,
        });
    }
};
