import React from 'react';
import { BrowserRouter as Router, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { Loading } from '@fairstone/ui/core/components/Loading';
import { getRouteTranslations } from '@fairstone/ui/core/routes';
import { t, translate } from '@fairstone/ui/core/utils/translate';

import AnalyticsLayout from 'layouts/Analytics/AnalyticsLayout';
import AppLayout from 'layouts/App';
import { TechnicalDifficulties } from 'pages/TechnicalDifficulties/TechnicalDifficulties';
import { useAppSelector } from 'store/redux/hooks';
import { selectIqAuth } from 'store/redux/modules/application';

const Introduction = React.lazy(() => import('pages/Introduction'));
const SessionExpired = React.lazy(() => import('pages/SessionExpired/SessionExpired'));
const StepsRouter = React.lazy(() => import('./StepsRouter'));
const Review = React.lazy(() => import('pages/Review'));
const YourQuote = React.lazy(() => import('pages/YourQuote'));
const ErrorPage = React.lazy(() => import('pages/Error'));

export default (): React.ReactElement => {
    const IQ_AUTH = useAppSelector(selectIqAuth);

    return (
        <React.Suspense fallback={<Loading />}>
            <Router>
                <Routes>
                    <Route
                        element={
                            <AppLayout>
                                <AnalyticsLayout>
                                    <Outlet />
                                </AnalyticsLayout>
                            </AppLayout>
                        }
                    >
                        {getRouteTranslations('routes.introduction.uri').map((path) => (
                            <Route element={<Introduction />} key={`route-${path}`} path={path} />
                        ))}
                        {getRouteTranslations('routes.sessionTimeout.uri').map((path) => (
                            <Route element={<SessionExpired />} key={`route-${path}`} path={path} />
                        ))}
                        {getRouteTranslations('routes.loanApplication.uri').map((path) => (
                            <Route
                                element={
                                    IQ_AUTH ? (
                                        <StepsRouter />
                                    ) : (
                                        <Navigate
                                            to={{
                                                pathname: translate('routes.introduction.uri'),
                                                search: location.search,
                                            }}
                                        />
                                    )
                                }
                                key={`route-${path}`}
                                path={path.concat('/*')}
                            />
                        ))}
                        {getRouteTranslations('routes.review.uri').map((path) => (
                            <Route
                                element={
                                    IQ_AUTH ? (
                                        <Review />
                                    ) : (
                                        <Navigate
                                            to={{
                                                pathname: translate('routes.introduction.uri'),
                                                search: location.search,
                                            }}
                                        />
                                    )
                                }
                                key={`route-${path}`}
                                path={path}
                            />
                        ))}
                        {getRouteTranslations('routes.instantQuote.uri').map((path) => (
                            <Route element={<YourQuote />} key={`route-${path}`} path={path} />
                        ))}
                        {getRouteTranslations('routes.errorPage.uri').map((path) => (
                            <Route element={<TechnicalDifficulties />} key={`route-${path}`} path={path} />
                        ))}
                        <Route element={<ErrorPage buttonText={t('error.back')} title={t('error.404')} />} path="*" />
                    </Route>
                </Routes>
            </Router>
        </React.Suspense>
    );
};
