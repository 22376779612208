// TODO: call the backend api
import axios, { AxiosInstance, AxiosResponse } from 'axios';

import { API_KEY, API_URL } from 'config/constants';
import { IQuotePayload, IResponseQuote } from 'store/redux/modules/application/types';

export const restAPI: AxiosInstance = axios.create({
    baseURL: API_URL,
});

restAPI.interceptors.request.use(async (config) => {
    config.headers['x-api-key'] = API_KEY;
    return config;
});

export const submitQuote = async (quote: IQuotePayload): Promise<AxiosResponse<IResponseQuote>> =>
    restAPI.post(`/loanapp/quote/submit`, quote);
