import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSessionManager } from '@fairstone/functional/core/components/SessionManager/SessionManagerProvider';
import { Typography } from '@fairstone/ui/core';
import { translate } from '@fairstone/ui/core/utils/translate';

import { onResetEvent } from 'store/redux/actions';
import { useAppDispatch } from 'store/redux/hooks';

import styles from './SessionPopUpScreen.module.scss';

export const SessionPopUpScreen: React.FC = () => {
    const { isActive } = useSessionManager();
    const dispatch = useAppDispatch();
    const { locale } = useIntl();

    useEffect(() => {
        if (!isActive) {
            dispatch(onResetEvent({ locale, path: translate('routes.sessionTimeout.uri') }));
        }
    }, [isActive, locale, dispatch]);
    return (
        <div className={styles.sessionPopUp}>
            <Typography variant="h1">{translate('session.popup.title')}</Typography>
            <Typography variant="caption">{translate('session.popup.warning')}</Typography>
        </div>
    );
};
