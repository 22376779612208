import React from 'react';
import { Button } from '@fairstone/ui/core/components/Button';
import { Card } from '@fairstone/ui/core/components/Card';
import { Typography } from '@fairstone/ui/core/components/Typography';
import { t } from '@fairstone/ui/core/utils/translate';
import errorImg from 'images/404-piggy-bank.svg';

import styles from './ErrorPage.module.scss';

interface IErrorProps {
    buttonText?: React.ReactElement;
    resetErrorBoundary?: (...args: Array<unknown>) => void;
    title?: React.ReactElement;
}

const ErrorPage = ({ buttonText, resetErrorBoundary, title }: IErrorProps): React.ReactElement => {
    const callToActionHandler = resetErrorBoundary ? resetErrorBoundary : () => window.history.back();
    return (
        <div className={styles.container}>
            <Card>
                <div className={styles.centerChildren}>
                    <Typography className={styles.title} gutterBottom variant="h1">
                        {title}
                    </Typography>
                    <Typography className={styles.margin} gutterBottom variant="body3">
                        {t('error.message')}
                    </Typography>
                    <img className={styles.margin} src={errorImg} />
                    <Button
                        appearance="contained"
                        border
                        className={styles.margin}
                        onClick={callToActionHandler}
                        size="large"
                    >
                        {buttonText}
                    </Button>
                </div>
            </Card>
        </div>
    );
};

export default ErrorPage;
