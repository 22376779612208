import { EnvEnum, TenvSingleProd } from '@fairstone-frontend/utils/core/types';

export const API_URL = process.env.REACT_APP_API_URL;
export const API_KEY = process.env.REACT_APP_API_KEY;
export const CANADA_POST_KEY = process.env.REACT_APP_CANADA_POST_KEY
    ? (process.env.REACT_APP_CANADA_POST_KEY as string)
    : '';

export const ORG_ID = process.env.REACT_APP_ORG_ID;

export const GTM_ID = process.env.REACT_APP_GTM_ID || '';

export const CURRENT_ENV = process.env.REACT_APP_CURRENT_ENV
    ? (process.env.REACT_APP_CURRENT_ENV as keyof TenvSingleProd)
    : EnvEnum.LOCAL;

export const ENABLE_CACHING = process.env.REACT_APP_ENABLE_CACHING || false;
export const CACHE_TIME = process.env.REACT_APP_CACHE_TIME || 300 * 1000; // 5 minutes
export const CACHE_PREFIX = process.env.REACT_CACHE_PREFIX || 'CACHE_';

export const TIMEOUT = 60000;

export const yearList = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];

export const monthList = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

// below timers are in minutes
export const SESSION_POPUP_TIMER = 5;

export const LOGROCKET_APPNAME = process.env.REACT_APP_LOGROCKET_APPNAME || '';
export const TRACKJS_APPNAME = process.env.REACT_APP_TRACKJS_APPNAME || '';
export const TRACKJS_KEY = process.env.REACT_APP_TRACKJS_KEY || '';

export const FEATURE_PROVIDER = process.env.REACT_APP_FEATURE_PROVIDER
    ? JSON.parse(process.env.REACT_APP_FEATURE_PROVIDER)
    : {};

export const EVENT_BRIDGE_URL = process.env.REACT_APP_EVENT_BRIDGE_URL || '';
export const EVENT_BRIDGE_API_KEY = process.env.REACT_APP_EVENT_BRIDGE_API_KEY || '';

export const DYNATRACE_URL = process.env.REACT_APP_DYNATRACE_URL || '';

export const REGION = process.env.REACT_APP_CURRENT_REGION || '';
