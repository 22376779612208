import React from 'react';
import { useRouteObserver } from '@fairstone/ui/core/components/utils/RouteObserver';

import { REGION } from 'config/constants';
import { useAnalyticsServices } from 'utils/AnalyticsService';

const AnalyticsLayout: React.FC<React.PropsWithChildren> = ({ children }) => {
    useRouteObserver();
    const { pushDataLayer } = useAnalyticsServices();

    pushDataLayer({ region: REGION });

    return <div>{children}</div>;
};

export default AnalyticsLayout;
