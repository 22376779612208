import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ERentOrOwn } from '../steps/types';

import { IYourQuoteState } from './types';

export const initialState: IYourQuoteState = {
    tempInfo: {
        amountRequested: 0,
        annualIncome: 0,
        firstName: '',
        province: '',
        reason: '01',
        rentOrOwn: ERentOrOwn.nothing,
        yearOfBirth: '',
    },
};

export const yourQuoteSlice = createSlice({
    initialState,
    name: 'yourQuote',
    reducers: {
        resetYourQuote: (state) => {
            state.tempInfo = initialState.tempInfo;
        },
        updateYourQuote(state, { payload }: PayloadAction<IYourQuoteState['tempInfo']>) {
            state.tempInfo = payload;
        },
    },
});
