export interface IStepsState {
    totalSteps: number;
    currentStep: number;
    stepVisited: ESteps;
    customerInformation: ICustomerInformation;
    loanInformation: ILoanInformation;
}

export enum ESteps {
    INTRODUCTION = 'introduction',
    AMOUNT = 'amount',
    REASON = 'reason',
    DEMOGRAPHIC = 'demographic',
    ADDRESS = 'address',
    HOUSING_DETAILS = 'housingDetails',
    CONTACT_INFO = 'contactInfo',
    INCOME = 'income',
    REVIEW = 'review',
}

export enum ERentOrOwn {
    rent = 'rent',
    own = 'own',
    nothing = 'nothing',
}

export interface ICustomerInformation {
    // Customer Information
    address: TCustomerAddressInformation;
    mobilePhone: string;
    otherPhone: string;
    emailAddress: string;
    stayUpToDate?: boolean;
    income?: number;
    sin: string;
    firstName: string;
    lastName: string;
    dob: TDateOfBirth;
    housingDetails: {
        yearsLived: number;
        monthsLived: number;
        rentOrOwn: ERentOrOwn;
        monthlyAmount?: number;
    };
}

// Loan Information
export type TLoanReason = '01' | '02' | '03' | '09' | '10' | '16';

export type TDateOfBirth = {
    day: string;
    month: string;
    year: string;
};
export interface ILoanInformation {
    borrow: number;
    reason?: TLoanReason;
}

export type TCustomerIncome = Pick<ICustomerInformation, 'income'>;
export type TCustomerSin = Pick<ICustomerInformation, 'sin'>;
export type TCustomerIncomeAndSinInformation = Pick<ICustomerInformation, 'income' | 'sin'>;

export type TLoanBorrowInformation = Pick<ILoanInformation, 'borrow'>;

export type TLoanReasonInformation = Pick<ILoanInformation, 'reason'>;

// names and data of birth
export type TCustomerNameAndDobInformation = Pick<ICustomerInformation, 'firstName' | 'lastName' | 'dob'>;
export type TCustomerFirstName = Pick<ICustomerInformation, 'firstName'>;
export type TCustomerLastName = Pick<ICustomerInformation, 'lastName'>;
export type TCustomerDateOfBirth = Pick<ICustomerInformation, 'dob'>;

// address
export type TCustomerAddressInformation = {
    city: string;
    postalCode: string;
    province: string;
    streetName: string;
    unit?: number | string;
};

// housing details
export type TCustomerHousingDetails = {
    yearsLived: number;
    monthsLived: number;
    rentOrOwn: ERentOrOwn;
    monthlyAmount?: number;
};
export type THousingSince = Pick<TCustomerHousingDetails, 'yearsLived' | 'monthsLived'>;
export type THousingPayment = Pick<TCustomerHousingDetails, 'rentOrOwn' | 'monthlyAmount'>;
export type TRentOrOwn = 'rent' | 'own' | 'nothing';

// contact
export type TPhoneNumbers = Pick<ICustomerInformation, 'mobilePhone' | 'otherPhone'>;
export type TEmails = Pick<ICustomerInformation, 'emailAddress' | 'stayUpToDate'>;
export type TCustomerContactInformation = Pick<
    ICustomerInformation,
    'mobilePhone' | 'otherPhone' | 'emailAddress' | 'stayUpToDate'
>;
