import { RootState } from 'types/store';

import { allianceUserSlice } from './reducers';
import { IAllianceUserState } from './types';

export const selectIsAllianceUser = (state: RootState): IAllianceUserState['isAllianceUser'] =>
    state.allianceUser.isAllianceUser;
export const selectAllianceUserQuestions = (state: RootState): IAllianceUserState['questions'] =>
    state.allianceUser.questions;

export const { resetAllianceUser, updateAllianceUser, updateQuestions } = allianceUserSlice.actions;

export default allianceUserSlice.reducer;
