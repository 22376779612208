import React from 'react';
import { Provider } from 'react-redux';
import { BrazeNotificationsProvider } from '@fairstone/functional/core/components/BrazeNotifications';
import { SessionManagerProvider } from '@fairstone/functional/core/components/SessionManager';
import { DynatraceProvider } from '@fairstone/ui/core/providers/Dynatrace';
import { FeatureFlagsProvider } from '@fairstone/ui/core/providers/FeatureFlags/FeatureFlagsProvider';
import { LocaleContextProvider } from '@fairstone/ui/core/providers/Intl';
import { Intl as IntlProvider } from '@fairstone/ui/core/providers/Intl';
import { PersistGate } from 'redux-persist/integration/react';

import { DYNATRACE_URL, FEATURE_PROVIDER, SESSION_POPUP_TIMER } from 'config/constants';
import translations, { locales } from 'locales';
import { persistedStore, store } from 'store/redux/store';
import { INITIAL_LANGUAGE } from 'utils/constants';
import { sessionModalText } from 'utils/sessionModalText';

import ThemeProvider from './Theme';

export interface IProvider {
    children: React.ReactNode;
}

export default ({ children }: IProvider): React.ReactElement => (
    <DynatraceProvider src={DYNATRACE_URL}>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistedStore}>
                <ThemeProvider>
                    <LocaleContextProvider>
                        <FeatureFlagsProvider configs={FEATURE_PROVIDER}>
                            <IntlProvider langCode={INITIAL_LANGUAGE} locales={locales} translations={translations}>
                                <BrazeNotificationsProvider>
                                    <SessionManagerProvider
                                        activitateSessionTimeout={false}
                                        onFinishTimeout={() => {
                                            /**/
                                        }}
                                        sessionTimeoutPopup={SESSION_POPUP_TIMER}
                                        text={sessionModalText}
                                    >
                                        {children}
                                    </SessionManagerProvider>
                                </BrazeNotificationsProvider>
                            </IntlProvider>
                        </FeatureFlagsProvider>
                    </LocaleContextProvider>
                </ThemeProvider>
            </PersistGate>
        </Provider>
    </DynatraceProvider>
);
