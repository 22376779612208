import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IAllianceUserState } from './types';

export const initialState: IAllianceUserState = {
    isAllianceUser: false,
    questions: { question1: false, question2: false, question3: false },
};

export const allianceUserSlice = createSlice({
    initialState,
    name: 'allianceUser',
    reducers: {
        resetAllianceUser(state) {
            state.isAllianceUser = initialState.isAllianceUser;
            state.questions = initialState.questions;
        },
        updateAllianceUser(state, action: PayloadAction<IAllianceUserState['isAllianceUser']>) {
            state.isAllianceUser = action.payload;
        },
        updateQuestions(state, action: PayloadAction<IAllianceUserState['questions']>) {
            state.questions = action.payload;
        },
    },
});
