import storage from 'redux-persist/lib/storage/session';

export const rootPersistConfig = {
    key: 'root',
    storage,
    version: 1.2,
    whitelist: ['steps'],
};

export const applicationPersistConfig = {
    key: 'application',
    storage: storage,
    whitelist: ['IQ_AUTH'],
};
