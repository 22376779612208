import { RootState } from 'types/store';

import { yourQuoteSlice } from './reducers';
import { IYourQuoteState } from './types';

export const selectYourQuoteState = (state: RootState): IYourQuoteState => state.yourQuote;

export const { resetYourQuote, updateYourQuote } = yourQuoteSlice.actions;

export default yourQuoteSlice.reducer;
