import React from 'react';
import { useIntl } from 'react-intl';
import { Header } from '@fairstone/ui/core/components/layouts/Header';
import { t, translate } from '@fairstone/ui/core/utils/translate';

import { localesMapping } from 'locales';

import styles from './AppLayout.module.scss';

interface IAppLayoutProps {
    disableHeader?: boolean;
}

const AppLayout: React.FC<React.PropsWithChildren<IAppLayoutProps>> = ({ children, disableHeader = false }) => {
    const intl = useIntl();

    const headerTranslations = {
        localeAriaLabel: intl.formatMessage({ id: 'localeSwitcher.aria-label' }),
        localeLabel: t('localeSwitcher.label'),
        logoLink: translate('global.link.fairstone'),
        phoneLabel: translate('global.phone-label'),
        phoneNumber: translate('global.assistance-number'),
    };

    return (
        <div className={styles.appWrapper}>
            <Header
                className={styles.header}
                dictionary={headerTranslations}
                disabled={disableHeader}
                localesMapping={localesMapping}
            />
            <main className={styles.main}>{children}</main>
            <div data-testid="gliachat" id="gliachat" />
        </div>
    );
};

export default AppLayout;
