import React from 'react';
import ReactDOMClient from 'react-dom/client';
import TagManager from 'react-gtm-module';
import { configureLogrocket, configureTrackjs } from '@fairstone-frontend/utils/core/logs';
import { isEmpty } from 'lodash';

import { CURRENT_ENV, GTM_ID, LOGROCKET_APPNAME, TRACKJS_APPNAME, TRACKJS_KEY } from 'config/constants';
import Providers from 'providers';
import Router from 'routes';

import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import '@fairstone/style/themes/fairstone/main.scss';

const tagManagerArgs = {
    gtmId: GTM_ID,
};

TagManager.initialize(tagManagerArgs);

if (!isEmpty(TRACKJS_APPNAME) && !isEmpty(TRACKJS_KEY)) {
    configureTrackjs(TRACKJS_APPNAME, TRACKJS_KEY, CURRENT_ENV);
}

if (!isEmpty(LOGROCKET_APPNAME)) {
    configureLogrocket(LOGROCKET_APPNAME, CURRENT_ENV);
}

// Create a root.
const root = ReactDOMClient.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <Providers>
        <App>
            <Router />
        </App>
    </Providers>
);

serviceWorkerRegistration.register();
